import React from "react"
import {
  BlogCard,
  CarousellLeft,
  CarousellRight,
  Container,
  BlogImage,
  BlogDescription,
  BlogTitle,
  BlogReadTime,
  BlogFlex,
  Arrow,
} from "../../../ToolsStyles/BlogSectionStyled"

import BlogImage1 from "../../../ToolBlogImages/ConvertJPGToPDF.avif"
import BlogImage2 from "../../../ToolBlogImages/RotateBlog.avif"
import BlogImage3 from "../../../ToolBlogImages/Merge.avif"
import BlogImage4 from "../../../ToolBlogImages/Extract-Text-Blog-image.png"

import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import { navigate } from "gatsby"
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1174 },
    items: 4,
  },
  laptop: {
    breakpoint: { max: 1174, min: 900 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 900, min: 400 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 400, min: 0 },
    items: 1,
  },
}
const BlogSection = () => {
  return (
    <Container>
      <Carousel responsive={responsive}>
        <BlogCard
          onClick={() => navigate("/blog/guides/how-to-convert-jpg-to-pdf/")}
        >
          <BlogImage src={BlogImage1} alt="blog-image" />
          <BlogTitle>How to Convert JPG to PDF (2023 Guide)</BlogTitle>
          <BlogDescription>
            Learn how to convert JPG to PDF on Windows, Mac, iPhone, & Android!
            Free, online, or with a PDF editor. The best free tool for 2023.
          </BlogDescription>
          <BlogReadTime>6 min read</BlogReadTime>
        </BlogCard>
        <BlogCard onClick={() => navigate("/blog/guides/how-to-rotate-a-pdf/")}>
          <BlogImage src={BlogImage2} alt="blog-image" />
          <BlogTitle>How to Rotate a PDF in 2023</BlogTitle>
          <BlogDescription>
            Whether you need to rotate a single page of one document or an
            entire PDF document, we’ll show you the best methods to rotate PDFs
            on your device.
          </BlogDescription>
          <BlogReadTime>5 min read</BlogReadTime>
        </BlogCard>
        <BlogCard
          onClick={() =>
            navigate("/blog/guides/how-to-merge-jpg-files-into-one-pdf/")
          }
        >
          <BlogImage src={BlogImage3} alt="blog-image" />
          <BlogTitle>
            How to Merge JPG Files into One PDF (Step-by-Step Guide)
          </BlogTitle>
          <BlogDescription>
            Learn how to merge JPG files to PDF step-by-step! Free and paid
            methods, for Windows & Mac!
          </BlogDescription>
          <BlogReadTime>5 min read</BlogReadTime>
        </BlogCard>
        <BlogCard
          onClick={() => navigate("/blog/guides/extract-text-from-pdf/")}
        >
          <BlogImage src={BlogImage4} alt="blog-image" />
          <BlogTitle>Extract Text from PDF</BlogTitle>
          <BlogDescription>
            Step-by-step instructions on the easiest ways to extract text from
            PDFs. Extract text from PDFs, images, scanned documents,
            copy/pasting, and more.
          </BlogDescription>
          <BlogReadTime>2 min read</BlogReadTime>
        </BlogCard>
      </Carousel>
    </Container>
  )
}

export default BlogSection
